import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['item']
  static values = { url: String, page: Number }

  connect() {
    this.onDrag = false

    Sortable.create(this.element, {
      handle: '.sort-handle',
      animation: 150,
      onUpdate: () => this.reorderItems(),
      onStart: () => (this.onDrag = true),
      onEnd: () => (this.onDrag = false),
    })

    this.itemTargets.forEach((t) => {
      t.addEventListener('mouseenter', this.addHover.bind(this))
      t.addEventListener('mouseleave', this.removeHover.bind(this))
    })
  }

  reorderItems() {
    const data = new FormData()
    data.append('ids', this.orderedIds)
    if (this.pageValue) {
      data.append('page', this.pageValue)
    }
    patch(this.urlValue, {
      body: data,
      responseKind: 'turbo-stream',
    })
  }

  // Manually add and remove hover styles to avoid the next element from
  // having hover style. For more information, please refer to: https://github.com/SortableJS/Sortable/issues/232
  // AND https://github.com/ArrowsHQ/Arrows/pull/3811.
  addHover(event) {
    if (!this.onDrag) {
      event.currentTarget.classList.add('hover:bg-neutral-50')

      const sort_element = event.currentTarget.querySelector('.sort-handle')
      sort_element.classList.toggle('group-hover:flex')

      const delete_element = event.currentTarget.querySelector('.delete-handle')
      delete_element.classList.toggle('group-hover:flex')

      const row_icon = event.currentTarget.querySelector('.defaut-icon')
      row_icon.classList.add('group-hover:hidden')
    }
  }

  removeHover(event) {
    event.currentTarget.classList.remove('hover:bg-neutral-50')

    const sort_element = event.currentTarget.querySelector('.sort-handle')
    sort_element.classList.remove('group-hover:flex')

    const delete_element = event.currentTarget.querySelector('.delete-handle')
    delete_element.classList.remove('group-hover:flex')

    const row_icon = event.currentTarget.querySelector('.defaut-icon')
    row_icon.classList.remove('group-hover:hidden')
  }

  get orderedIds() {
    return this.itemTargets.map((t) => t.dataset.itemId)
  }
}
