import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['blockList', 'addButton', 'addSectionOnlyButton']

  toggleAddButtonVisibility(_event) {
    if (this.element.open) {
      if (this.blocks.length > 0) {
        this.addButtonTarget.classList.remove('hidden')
        this.addSectionOnlyButtonTarget.classList.add('hidden')
      } else {
        this.addButtonTarget.classList.add('hidden')
        this.addSectionOnlyButtonTarget.classList.add('hidden')
      }
    } else {
      this.addButtonTarget.classList.add('hidden')
      this.addSectionOnlyButtonTarget.classList.remove('hidden')
    }
  }

  get blocks() {
    return this.blockListTarget.querySelectorAll('[data-kind="block"]')
  }
}
