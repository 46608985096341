import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static values = {
    group: { type: String, default: 'blocks' },
  }

  connect() {
    Sortable.create(this.element, {
      group: {
        name: this.groupValue,
      },
      handle: '.sort-handle',
      animation: 150,
      forceFallback: true,
      filter: '.sortable-ignore',
      onMove: (event) => {
        const pinnedSection = this.element.querySelector(
          '[data-pinned-section]'
        )

        // Check if user about to drop item above pinned top item.
        if (
          event.related === pinnedSection &&
          event.willInsertAfter === false
        ) {
          // Cancel drop attempt.
          return false
        }

        // Doing something else, allow.
        return true
      },
    })
  }

  sectionHeaderClick(event) {
    if (
      event.target.tagName === 'SUMMARY' ||
      event.target.classList.contains('sort-handle')
    ) {
      event.preventDefault()
    }
  }
}
