import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'customAssignee', 'taskAssigneeWarning']

  connect() {
    this.updateVisibility()
  }

  onSelect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const selectedValue = this.selectTarget.value

    if (selectedValue === 'custom_user') {
      this.customAssigneeTarget.classList.remove('hidden')
    } else {
      this.customAssigneeTarget.classList.add('hidden')
    }

    if (selectedValue === 'task_assignee') {
      this.taskAssigneeWarningTarget.classList.remove('hidden')
    } else {
      this.taskAssigneeWarningTarget.classList.add('hidden')
    }
  }
}
