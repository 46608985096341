export function lockBodyScrolling() {
  const scrollbarWidth = getScrollbarWidth()

  document.body.style.overflow = 'hidden'
  if (scrollbarWidth > 0) {
    document.body.style.paddingRight = `${scrollbarWidth}px`
  }
}

export function unlockBodyScrolling() {
  document.body.style.overflow = ''
  document.body.style.paddingRight = ''
}

function getScrollbarWidth() {
  return window.innerWidth - document.documentElement.clientWidth
}
