import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['searchField']

  resetSearchField() {
    this.searchFieldTarget.value = ''
    this.searchFieldTarget.focus()
  }
}
