{
    let _global =
      typeof window !== 'undefined' ?
        window :
        typeof global !== 'undefined' ?
          global :
          typeof globalThis !== 'undefined' ?
            globalThis :
            typeof self !== 'undefined' ?
              self :
              {};

    _global.SENTRY_RELEASE={id:"a46607b83d1a42ffac17fc42a2daab8a746af1c3"};}