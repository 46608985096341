import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['select', 'valueDisplay']
  static values = {
    refreshUrl: String,
  }

  refreshPropertyValues() {
    const propertyId = this.selectTarget.value

    if (propertyId) {
      get(this.refreshUrlValue, {
        query: {
          hubspot_property_id: propertyId,
        },
        responseKind: 'turbo-stream',
      })
      this.valueDisplayTarget.classList.remove('hidden')
    } else {
      this.valueDisplayTarget.classList.add('hidden')
    }
  }
}
