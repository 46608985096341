import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect() {
    Sortable.create(this.element, {
      handle: '.sort-handle',
      animation: 150,
      forceFallback: true,
      filter: '.sortable-ignore',
    })
  }
}
