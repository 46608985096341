import { Controller } from '@hotwired/stimulus'
import { copyText, copyHtml } from '../helpers/clipboard_helpers'

export default class extends Controller {
  static targets = ['source', 'button', 'buttonText', 'copyIcon', 'successIcon']
  static classes = ['success']
  static values = {
    successText: { type: String, default: 'Copied!' },
  }

  copy(event) {
    event.preventDefault()
    copyText(this.sourceTarget.value || this.sourceTarget.innerText)

    const originalText = this.buttonTextTarget.innerHTML
    this.buttonTextTarget.innerText = this.successTextValue
    this.buttonTarget.classList.add(...this.successClasses)
    this.buttonTarget.disabled = true

    if (this.hasCopyIconTarget) {
      this.copyIconTarget.classList.add('hidden')
      this.successIconTarget.classList.remove('hidden')
    }

    if (this.textTimer) {
      clearTimeout(this.textTimer)
    }

    this.textTimer = setTimeout(() => {
      this.buttonTextTarget.innerHTML = originalText
      this.buttonTarget.classList.remove(...this.successClasses)
      this.buttonTarget.disabled = false

      if (this.hasCopyIconTarget) {
        this.copyIconTarget.classList.remove('hidden')
        this.successIconTarget.classList.add('hidden')
      }
    }, 2000)
  }

  copyLink(e) {
    e.preventDefault()
    e.stopPropagation()

    copyText(e.params.url)
    this.#displaySuccessMessage(e.params.message)
  }

  async copyHtmlFromElement(e) {
    e.preventDefault()
    e.stopPropagation()

    const element = document.querySelector(e.params.elementSelector)
    const result = await copyHtml(element)

    if (result) {
      this.#displaySuccessMessage(e.params.message)
    }
  }

  #displaySuccessMessage(message) {
    const originalHtml = this.element.innerHTML
    this.element.innerHTML = message
    this.element.disabled = true

    if (this.textTimer) {
      clearTimeout(this.textTimer)
    }

    this.textTimer = setTimeout(() => {
      this.element.innerHTML = originalHtml
      this.element.disabled = false
    }, 2000)
  }

  disconnect() {
    if (this.textTimer) {
      clearTimeout(this.textTimer)
    }
  }
}
