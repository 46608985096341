export async function copyText(text) {
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(text)
      return true
    } catch {
      // noop
    }
  }
  const textArea = createTextArea(text)
  document.body.append(textArea)
  const result = copyTextFromTextArea(textArea)
  document.body.removeChild(textArea)
  return result
}

export async function copyItems(items, fallbackText) {
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.write(items)
      return true
    } catch {
      // noop
    }
  }

  return copyText(fallbackText)
}

function createTextArea(text) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.opacity = 0
  textArea.style.position = 'absolute'
  textArea.style.left = '-9999px'
  return textArea
}

function copyTextFromTextArea(textArea) {
  textArea.select()
  return document.execCommand('copy')
}

export async function copyHtml(domNode) {
  const htmlContent = domNode.innerHTML
  const textContent = domNode.textContent

  // Try the modern Clipboard API with HTML support
  if (navigator.clipboard && typeof navigator.clipboard.write === 'function') {
    try {
      // Create a ClipboardItem with both HTML and text formats
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([htmlContent], { type: 'text/html' }),
        'text/plain': new Blob([textContent], { type: 'text/plain' }),
      })

      await navigator.clipboard.write([clipboardItem])
      return true
    } catch (err) {
      console.log('Clipboard API failed, falling back to execCommand', err)
      // Fall through to execCommand
    }
  }

  // Use legacy execCommand("copy") approach, needed for HubSpot iframes
  // Not supported in Safari
  const copyListener = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.clipboardData.setData('text/plain', textContent)
    e.clipboardData.setData('text/html', htmlContent)
  }
  var eventListenerOptions = {
    capture: true,
    once: true,
  }
  document.addEventListener('copy', copyListener, eventListenerOptions)

  var result = document.execCommand('copy')
  if (!result) {
    console.error('execCommand failed')
  }
  return result
}
