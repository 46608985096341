import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { overlayId: String }

  connect() {
    this.onToggle = this.onToggle.bind(this)

    this.overlayElement = document.getElementById(this.overlayIdValue)
    if (this.overlayElement) {
      this.overlayElement.addEventListener('toggle', this.onToggle)
    } else {
      throw new Error(
        `Could not find overlay element with id: ${this.overlayIdValue}`
      )
    }
  }

  disconnect() {
    this.overlayElement?.removeEventListener('toggle', this.onToggle)
  }

  onToggle(event) {
    this.element.dataset.overlayOpen = event.newState === 'open'
  }
}
