import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field']

  validateFieldValue(_event) {
    this.fieldTargets.forEach((field) => {
      if (field.value.trim().length === 0) {
        this.submitButton.disabled = true
        return
      } else {
        this.submitButton.disabled = false
      }
    })
  }

  get submitButton() {
    return this.element.querySelector('button[type="submit"]')
  }
}
