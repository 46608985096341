import Swal from 'sweetalert2'

Turbo.config.forms.confirm = (message, element) => {
  const submitter = element.querySelector('[type="submit"]')
  const title =
    submitter?.getAttribute('data-turbo-confirm-title') || 'Confirm action'
  const confirmButtonText =
    submitter?.getAttribute('data-turbo-confirm-btn-confirm-text') || 'Confirm'
  const cancelButtonText =
    submitter?.getAttribute('data-turbo-confirm-btn-cancel-text') || 'Cancel'

  return new Promise((resolve, _reject) => {
    Swal.fire({
      html: `
        <div class="flex flex-col space-y-4">
          <div class="text-left text-lg font-medium text-neutral-900">
            ${title}
          </div>
          <div class="text-left font-normal text-base text-neutral-900">
            ${message}
          </div>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      buttonsStyling: false,
      reverseButtons: true,
      width: 512,
      animation: false,
      customClass: {
        popup: 'rounded-3xl px-8 py-6',
        htmlContainer: "p-0",
        actions: 'justify-end m-0 px-0 pb-0 pt-4',
        confirmButton: 'btn-primary ml-3',
        cancelButton: 'btn-tertiary',
      },
    }).then((result) => resolve(result.isConfirmed))
  })
}
