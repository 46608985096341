import { Controller } from '@hotwired/stimulus'
import data from '@emoji-mart/data'
import { Picker } from 'emoji-mart'

export default class extends Controller {
  static targets = ['input', 'button', 'picker']

  connect() {
    this.initEmojiPicker()
  }

  disconnect() {
    if (this.pickerElement) {
      this.pickerElement.remove()
    }
  }

  initEmojiPicker() {
    this.pickerElement = new Picker({
      onEmojiSelect: (emoji) => this.selectEmoji(emoji),
      data: data,
      previewPosition: 'none',
      navPosition: 'none',
      perLine: 10,
      theme: 'light',
    })
    this.pickerTarget.appendChild(this.pickerElement)
  }

  togglePicker() {
    const display = this.pickerTarget.style.display

    this.pickerTarget.style.display = display === 'block' ? 'none' : 'block'

    if (this.pickerTarget.style.display === 'block') {
      this.pickerElement.shadowRoot
        .querySelector('input[type="search"]')
        ?.focus()
    }
  }

  selectEmoji(emoji) {
    const nativeEmoji = emoji.native
    this.inputTarget.value = nativeEmoji
    this.buttonTarget.innerHTML = nativeEmoji
    this.togglePicker()
  }
}
