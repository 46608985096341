import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['daysDisplay', 'engagementDays']

  update() {
    this.updateDisplays(this.engagementDaysTarget.value)
  }

  updateDisplays(value) {
    this.daysDisplayTargets.forEach((element) => {
      element.textContent = value
    })
  }
}
