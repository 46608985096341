import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['section']
  static values = {
    sectionsExpanded: { type: Boolean, default: true }
  }

  connect() {
    // Initialize with sections expanded (as they appear in the UI by default)
    this.sectionsExpandedValue = true
  }

  expandAll() {
    this.sectionTargets.forEach((section) => {
      section.open = true
    })
    this.sectionsExpandedValue = true
  }

  collapseAll() {
    this.sectionTargets.forEach((section) => {
      section.open = false
    })
    this.sectionsExpandedValue = false
  }

  toggleSections(event) {
    if (this.sectionsExpandedValue) {
      this.collapseAll()
      event.target.textContent = "Expand sections"
    } else {
      this.expandAll()
      event.target.textContent = "Collapse sections"
    }
  }
}
