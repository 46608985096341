import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item', 'label', 'field', 'form', 'button', 'searchField']

  connect() {
    this.selectedItems = new Set([])
    this.#syncItemField()
  }

  selectItem(event) {
    if (event.target.checked) {
      this.selectedItems.add(event.target.value)
    } else {
      this.selectedItems.delete(event.target.value)
    }

    this.#syncItemField()
  }

  itemTargetConnected(target) {
    if (this.selectedItems.has(target.value)) {
      target.checked = true
    }
  }

  resetSearchField() {
    this.searchFieldTarget.value = ''
    this.searchFieldTarget.focus()
  }

  #syncItemField() {
    if (this.selectedItems.size > 0) {
      this.labelTarget.innerHTML = `${this.selectedItems.size} selected`
      this.buttonTarget.disabled = false
    } else {
      this.labelTarget.innerHTML = ''
      this.buttonTarget.disabled = true
    }

    this.fieldTarget.value = Array.from(this.selectedItems).join(',')
  }
}
