import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
  }

  onSelect(event) {
    const fieldType = event.target.value

    get(this.urlValue, {
      query: { field_type: fieldType },
      responseKind: 'turbo-stream',
    })
  }
}
